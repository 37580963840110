
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import './styles/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Index from './components/home/index';
import DigitalCard from './components/digitalcard/index';
import About from './components/about/index';
import Contact from './components/contact/index';
import Login from './components/login/index';
import Detail from './components/detail/index';
import Pricing from './components/pricing/index';
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";


function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/digitalcard">
          <DigitalCard />
        </Route>
        <Route exact path="/">
          <Index />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/abouts/:id">
          <About />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/detail">
          <Detail />
        </Route>
        <Route path="/details/:id">
          <Detail />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
