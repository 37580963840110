import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card } from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
export default function aboutlogin({ featureDivRef }) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <>
            <div>
                <div className='about_login_sec'>
                    <Container className='container-new-big'>
                        <Row>
                            <Col lg={6}>
                                <Carousel responsive={responsive} infinite={true}>
                                    <div className='caro_item'>
                                        <div className='about_count'>
                                            <p>1</p>
                                        </div>
                                        <div className='about_caro_content'>
                                            <h3>
                                                Create, Secure & Share
                                            </h3>
                                            <p>
                                                Customize your business profile and securely store all your personal and official documents in a sharable digital space.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='caro_item'>
                                        <div className='about_count'>
                                            <p>2</p>
                                        </div>
                                        <div className='about_caro_content'>
                                            <h3>
                                                Experience ACW Card Magic
                                            </h3>
                                            <p>
                                                Simply select a smartphone. Activation of the best NFC card is a breeze, taking less than a minute.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='caro_item'>
                                        <div className='about_count'>
                                            <p>3</p>
                                        </div>
                                        <div className='about_caro_content'>
                                            <h3>
                                                Networking Redefined
                                            </h3>
                                            <p>
                                                Step into a new era of networking. Your custom NFC business cards open doors to exciting opportunities, empowering you to connect in style.
                                            </p>
                                        </div>
                                    </div>
                                </Carousel>
                            </Col>
                            <Col lg={6}>
                                <div className='login_mob_img'>
                                    <img src='../images/about/aboutiphone.png' alt='aboutiphone' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}
