"use client";
import React from 'react';
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
export default function Header() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const location = useLocation();
    return (
        <div>
            <header className='section'>
                <Container className='container-new-big'>
                    <Navbar collapseOnSelect expand="lg">
                        <Navbar.Brand href="/">
                            <img src='../images/logo.png' alt='logo' />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbarScroll" />
                        <Navbar.Collapse id="navbarScroll" className='justify-content-end'>
                            <Nav
                                className="ml-auto my-2 my-lg-0 acw_navbar"
                                navbarScroll
                            >
                                <Nav.Link href="/" className={location.pathname === '/' ? 'active_header' : ''} title='Home'>Home</Nav.Link>
                                <Nav.Link href="/digitalcard" className={location.pathname === '/digitalcard' ? 'active_header' : ''} title='ACW Digital Card'>ACW Digital Card</Nav.Link>
                                <Nav.Link href="/about" className={location.pathname === '/about' ? 'active_header' : ''} title='How it works'>How It Works</Nav.Link>
                                {/* <NavDropdown title="Platform" id="navbarScrollingDropdown">
                                    <NavDropdown.Item href="#action3">Platform </NavDropdown.Item>
                                    <NavDropdown.Item href="#action4">
                                        Another action
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <NavDropdown title="Solutions" id="navbarScrollingDropdown">
                                    <NavDropdown.Item href="#action3">Solutions </NavDropdown.Item>
                                    <NavDropdown.Item href="#action4">
                                        Another action
                                    </NavDropdown.Item>
                                </NavDropdown> */}
                                <Nav.Link href="/pricing" className={location.pathname === '/pricing' ? 'active_header' : ''} title='Pricing'>
                                    Pricing
                                </Nav.Link>
                                <Nav.Link href="/contact" className={location.pathname === '/contact' ? 'active_header' : ''} title='Contact'>
                                    Contact
                                </Nav.Link>
                                <Nav.Link href="/login" className={location.pathname === '/login' ? 'active_header login_btn' : 'login_btn'} title='Login'>
                                    Login
                                </Nav.Link>
                                <Nav.Link href="/detail#form-sec" className='order_card' title='Enquiry'>
                                    {/* <Button variant="outline-success">Enquiry</Button> */}
                                    <Link to='/details/enquiry-form'>
                                        <Button onClick={scrollEnquiry}>Enquiry</Button>
                                    </Link>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
            </header>
        </div>
    )
}
