"use client";
import React from 'react';
import { FaFacebookF, FaTwitter, FaYoutube, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
export default function Footer() {
  var today = new Date();
  var year = today.getFullYear();
  return (
    <>
      <div className='footer_section section pt-4'>
        <Container className='container-new-big'>
          <div className='footer_logo'>
            <a href='/'>
              <img src='../images/logo.png' alt='logo' />
            </a>
          </div>
          <ul className='footer_ul'>
            <li>
              <Link to='/digitalcard' title="ACW Digital Card">ACW Digital Card</Link>
            </li>
            <li>
              <Link to='/about' title="How it works">How It Works</Link>
            </li>
            {/* <li>
              <Link href='#' title="Platform">Platform</Link>
            </li>
            <li>
              <Link href='#' title="Solutions">Solutions</Link>
            </li> */}
            <li>
              <Link to='/pricing' title="Pricing">Pricing</Link>
            </li>
            <li>
              <Link to='/contact' title="Contact">Contact</Link>
            </li>
            <li>
              <Link to='/detail' title="Details">Details</Link>
            </li>
          </ul>
          <div className='footer_content'>
            <Row>
              <Col lg={4} md={6} sm={6} className='footer_lists'>
                {/* <h5>Get The App</h5> */}
                <ul>
                  {/* <li>
                    <a href='#' title="Blog">Blog</a>
                  </li>
                  <li>
                    <a href='#' title="FAQ">FAQ</a>
                  </li> */}
                  {/* <li>
                    <a href='#' title="Works With HiHello">Works With HiHello</a>
                  </li>
                  <li>
                    <a href='#' title="Help Center">Help Center</a>
                  </li> */}
                </ul>
              </Col>
              {/* <Col lg={3} md={6} sm={6} className='footer_lists'>
                <h5>Explore</h5>
                <ul>
                  <li>
                    <a href='#' title="NFC Business Cards">NFC Business Cards</a>
                  </li>
                  <li>
                    <a href='#' title="Digital Business Cards">Digital Business Cards</a>
                  </li>
                  <li>
                    <a href='#' title="Sharing Business Card">Sharing Business Card</a>
                  </li>
                  <li>
                    <a href='#' title="Digital Address Book">Digital Address Book</a>
                  </li>
                  <li>
                    <a href='#' title="NFC Business Cards">NFC Business Cards</a>
                  </li>
                </ul>
              </Col> */}
              <Col lg={4} md={6} sm={6} className='footer_lists'>
                {/* <h5>Company</h5> */}
                <ul>
                  {/* <li>
                    <Link to='/about'>About Us</Link>
                  </li> */}
                  {/* <li>
                    <a href='#' title="Careers">Careers</a>
                  </li> */}
                  {/* <li>
                    <Link to='/contact'>Contact Us</Link>
                  </li> */}
                  {/* <li>
                    <a href='#' title="Security">Security</a>
                  </li>
                  <li>
                    <a href='#' title="Privacy Summary">Privacy Summary</a>
                  </li> */}
                </ul>
              </Col>
              <Col lg={4} md={6} sm={6} className='footer_lists'>
                {/* <h5>Legal</h5> */}
                <ul>
                  {/* <li>
                    <a href='#' title="Privacy Policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href='#' title="Terms of Service">Terms Of Service</a>
                  </li> */}
                  {/* <li>
                    <a href='#' title="Shipping & Delivery Policy">Shipping & Delivery Policy</a>
                  </li>
                  <li>
                    <a href='#' title="Return & Refund Policy">Return & Refund Policy</a>
                  </li> */}
                  {/* <li>
                    <a href='#' title="FAQ's">FAQ's</a>
                  </li> */}
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className='copyrighs_section section'>
        <Container className='container-new-big'>
          <div className='copy_rights'>
            {/* <div>
              <p>support@acwcard.com</p>
            </div> */}
            <div>
              <ul className='social_media'>
                <li className='facebook'>
                  <a href='https://www.facebook.com/acwcard' target='_blank' title="Facebook"><FaFacebookF /></a>
                </li>
                <li className='twiiter'>
                  <a href='https://twitter.com/i/flow/login?redirect_after_login=%2Facwcardcom' title="Twiiter" target='_blank'><FaXTwitter /></a>
                </li>
                <li className='youtube'>
                  <a href='https://www.youtube.com/@acwcard' title="Youtube" target='_blank'><FaYoutube /></a>
                </li>
                <li className='linkedinn'>
                  <a href='https://www.linkedin.com/company/acwcard/' title="Linkedinn" target='_blank'><FaLinkedinIn /></a>
                </li>
                <li className='instagram'>
                  <a href='https://www.instagram.com/acwcard/' title="Instagram" target='_blank'><FaInstagram /></a>
                </li>
              </ul>
            </div>
            <div>
              <p>Copyright © <span id="year">{year}</span> All rights reserved. Powered by<a className="acw-txt" href='https://www.acwcircle.com/' target='_blank'> Aries Connects World</a> </p>
            </div>
          </div>
        </Container>
      </div>
    </>

  )
}
