import React, { useState } from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card, TabContent, TabPane } from 'react-bootstrap';
import { BiDollar } from 'react-icons/bi';
import { Redirect } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';

export default function Digitalcard() {
    const [activeTab, setActiveTab] = useState('tab1');
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const [redirect, setRedirect] = useState(false);

    const handleClick = () => {
        // history.push("/detail");
        setRedirect(true)
    }
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const responsivepvc = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div>
            {redirect && <Redirect to="/detail" />}
            <div className='section'>
                <div className='product_section digitalia_sec'>
                    <Container className='container-new-big'>
                        <h2>Pick Your Card </h2>
                        <div className='tab_1'>
                            <Tabs
                                activeKey={activeTab} onSelect={handleTabChange}
                                id="fill-tab-example"
                                className="mb-3"
                                fill
                            >
                                <Tab eventKey="tab1" title="Metal">
                                    <div className='tab_inner_page mt-5'>
                                        <h4 className='digital_card_head'>Metal Cards</h4>
                                        {/* <Row>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard01.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2999 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard02.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard04.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard05.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2510 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard06.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />3300 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard07.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1990 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard08.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2220 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard09.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Carousel responsive={responsive} infinite={true}>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/Untitled-1 1.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Blue <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard02.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Light Green  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard03.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Brown  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard04.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Red  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard05.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Navy Blue  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard06.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Black  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard07.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Purple  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard08.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Gray  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            {/* <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard09.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Blue  <br />
                                                                    Professional</h5>
                                                                <p>Metal</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />35 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                       
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div> */}
                                        </Carousel>
                                    </div>
                                </Tab>
                                <Tab eventKey="tab2" title="PVC">
                                    <div className='tab_inner_page mt-5'>
                                        <h4 className='digital_card_head'>PVC Cards</h4>
                                        {/* <Row>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard01.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2999 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard02.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard04.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard05.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2510 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard06.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />3300 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard07.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1990 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard08.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2220 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard09.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row> */}
                                        <Carousel responsive={responsive} infinite={true}>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/Untitled-1 1.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Blue<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard02.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Light Green<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard03.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Brown<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard04.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Red<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard05.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Navy Blue<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard06.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Black <br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard07.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Purple<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard08.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Gray<br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        {/* <Button className='digital_card_btn_2'>Enquiry</Button> */}
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            {/* <div className='digitalia_card_design'>
                                                <Card>
                                                    <Card.Img variant="top" src="../images/card/digitalcard09.png" />
                                                    <Card.Body>
                                                        <div className='card_sub'>
                                                            <div className='card_sub_1'>
                                                                <h5>Black metal <br />
                                                                    Professional</h5>
                                                                <p>PVC Glossy</p>
                                                            </div>
                                                            <div className='card_sub_2'>
                                                                <p className='cost_amo'> <BiDollar />30 </p>
                                                            </div>
                                                        </div>
                                                        <Link to='/abouts/features'>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                        </Link>
                                                        
                                                        <Link to='/details/enquiry-form'>
                                                            <Button className='digital_card_btn_2' onClick={scrollEnquiry}>Enquiry</Button>
                                                        </Link>

                                                    </Card.Body>
                                                </Card>
                                            </div> */}
                                        </Carousel>
                                    </div>
                                </Tab>
                                {/* <Tab eventKey="Others" title="Others">
                                    <div className='tab_inner_page mt-5'>
                                        <h4 className='digital_card_head'>Others</h4>
                                        <Row>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard01.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2999 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard02.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard03.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1 ' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard04.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1800 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard05.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2510 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard06.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />3300 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard07.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1990 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard08.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />2220 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} className='mt-4'>
                                                <div className='digitalia_card_design'>
                                                    <Card>
                                                        <Card.Img variant="top" src="../images/card/digitalcard09.png" />
                                                        <Card.Body>
                                                            <div className='card_sub'>
                                                                <div className='card_sub_1'>
                                                                    <h5>Black metal <br />
                                                                        Professional</h5>
                                                                    <p>PVC Glossy</p>
                                                                </div>
                                                                <div className='card_sub_2'>
                                                                    <p className='cost_amo'> <LiaRupeeSignSolid />1500 </p>
                                                                </div>
                                                            </div>
                                                            <Button className='digital_card_btn_1' onClick={handleClick}>Features</Button>
                                                            <Button className='digital_card_btn_2'>Enquiry</Button>

                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab> */}
                            </Tabs>
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}
