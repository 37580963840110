import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card } from 'react-bootstrap';
import { LiaRupeeSignSolid } from 'react-icons/lia';
import Select from 'react-select'
import { Link } from "react-router-dom";

export default function contact() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("product_link");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const options1 = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const options2 = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    return (
        <div className='contact_sec'>
            {/* <div className='explore_contain section pricing_section'>
                <Container className='container-new-big'>
                    <div className='explore_sec'>
                        <h2 className="pricing-txt">
                           Contact Us  
                        </h2>
                        <p className="pricing-para pt-0" >
                           Reach out to us to design your <strong> digital NFC cards.</strong>
                        </p>
                    </div>
                </Container>
            </div> */}
            <div className='order_sec section'>
                <div className='order_section'>
                    <div className='react_videoplayer_innerpage'>
                        {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                        <video autoPlay loop volume muted>
                            {/* Provide the video source */}
                            <source src="../videos/contact.mov" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </div>

            <div className='contact'>
                <Container className='container-new-big'>
                    <div className='contact_text1'>
                        <h2>Leave A Mail</h2>
                        <p>If you have chosen a plan or have a query, you can mail us at</p>
                        <a href='mailto:Support@acwcard.com '>support@acwcard.com </a>
                    </div>
                    <hr />
                </Container>


                <div className='form_part'>
                    <div className='sub_text_contact'>
                        <h3>Place A Query </h3>
                        <p>You can also write your query to us and we will get back to you. Fill your details here.</p>
                    </div>
                    <div className='enquiry_contact_btn'>
                        <Link to='/details/enquiry-form'>
                            <Button onClick={scrollEnquiry}>Enquiry</Button>
                        </Link>
                    </div>
                    {/* <form>
                        <div className="mb-3">
                            <label htmlFor="select" className="form-label" >Please select your card </label>
                            <Select options={options} id="selectbox"
                                instanceId="selectbox" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput2" className="form-label">Email Address</label>
                            <input type="email" className="form-control" id="exampleFormControlInput2" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput3" className="form-label">Phone Number</label>
                            <input type="text" className="form-control" id="exampleFormControlInput3" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="select2" className="form-label" id='1'>Region*</label>
                            <Select options={options1} id="selectboxone"
                                instanceId="selectboxone" />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="select3" className="form-label">Number Of Employees*</label>
                            <Select options={options2} id="selectboxtwo"
                                instanceId="selectboxtwo" />
                            
                        </div>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label"> Message</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                        <p className='contact_sub_div'>Please send us a message and attach the relevant details </p>

                        <label htmlFor="inputGroupFile02" className="form-label">Attachment</label>
                        <div className="input-group mb-3">
                            <input type="file" className="form-control" id="inputGroupFile02" />
                        </div>
                        <div className='contact_btn'>
                            <Button className='digital_card_btn_2'>Submit</Button>
                        </div>
                    </form> */}
                </div>

            </div>
        </div>
    )
}
