import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';

export default function Plan() {
    return (
        <>
             <div className='order_sec section'>
            <div className='order_section'>
                <div className='react_videoplayer_innerpage'>
                    {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                    <video autoPlay  loop volume muted>
                        {/* Provide the video source */}
                        <source src="../videos/pricing.mov" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
        </div>
        </>
    );
}