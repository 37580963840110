const LIVE = true;
const LIVEURL = LIVE ? "https://mystoreai.com/" : "http://localhost:5005/";
const SITEURL = LIVE ? "https://mystoreai.com/" : "http://localhost:3002/";
const ROOTURL = LIVEURL + 'api/v1/admin/';

const API = {
  addLandingPageEnquiry: ROOTURL + 'landingpageenquiry/addLandingPageEnquiry',
  login: ROOTURL + 'account/login',
}

const ImportedURL = {
  API: API,
  LIVEURL: LIVEURL,
  SITEURL: SITEURL,
  LIVE: LIVE,
}

export default ImportedURL;
