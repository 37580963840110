"use client";
import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function virtualcard() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <div className='virtual_sec section'>
            <Container className='container-new-big'>
                <Row>
                    <Col lg={6} className='d-flex align-items-center'>
                        <div className='virtual_image'>
                            <img src='../images/card/virtualcard.png' alt='virtualcard' />
                        </div>
                    </Col>
                    <Col lg={6} >
                        <div className='virtual_card_content'>
                            <h2>
                                The <strong> Best NFC Card </strong> - For Enterprises And Teams
                            </h2>
                            <h3>Individuals </h3>
                            <p>For personal brand and professional connections.</p>
                            <h3>Teams</h3>
                            <p>Boost collaboration and efficiency with digital business card for teams.</p>
                            <h3>Enterprises</h3>
                            <p>Transform your company's digital presence with <straong> digital NFC cards </straong>. It enables every member to identify their unique value while strengthening your brand's collective impact.</p>
                            <div className='virtual_btns'>
                                {/* <Button title='Features'>Features</Button> */}
                                {/* <Button title='Benefits'>Benefits</Button> */}
                                {/* <Button className='explore_virtual' title='Enquiry'>Enquiry</Button> */}
                                <Link to='/details/enquiry-form'>
                                    <Button className='explore_virtual' onClick={scrollEnquiry}>Enquiry</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
