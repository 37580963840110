"use client";
import React from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import { AiFillStar } from 'react-icons/ai';
import { Link } from 'react-router-dom';

export default function about() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <div className='about_section section' id='about'>
            <Container className='container-new-big'>
                <Row>
                    <Col lg={4}>
                        <div className='about_sec'>
                            <h2>
                                About ACW <br />
                                Card App
                            </h2>
                            <p>A leading platform and a convenient way to grow your network </p>
                            <ul className='about_ul'>
                                <li><span><AiFillStar /></span> <span className='about_list_span'>Share your <strong> digital NFC cards </strong> in seconds </span></li>
                                <li><span><AiFillStar /></span> <span className='about_list_span'> Easy to access mobile app.</span></li>
                                <li><span><AiFillStar /></span> <span> Turn your contacts into customers</span></li>
                            </ul>
                            {/* <Button className='mt-3' title='Enquiry'>Enquiry</Button> */}
                            {/* <Link to='/details/enquiry-form'>
                                <Button className='mt-3' onClick={scrollEnquiry}>Enquiry</Button>
                            </Link> */}
                        </div>
                    </Col>
                    <Col lg={8}>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className='about_img1 about_card_pic' >
                                    <img src='../images/aboutcard1.png' alt='create' />
                                </div>
                                <div className='about_img1'>
                                    <img src='../images/aboutcard2.png' alt='create' />
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12} className='mt-3'>
                                <div className='about_img2 mob_pic'>
                                    <img src='../images/aboutscreen.png' alt='create' />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
