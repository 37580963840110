"use client";
import React from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ready() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <div className='bulk_order_sec section ready_section'>
            <Container className='container-new-big'>
                <div className='bulk_order'>
                    <h2>
                        Ready To Elevate Your Digital Journey?
                    </h2>
                    <p>Improve your networking game and effortlessly manage your documents in the digital age with ACW <strong> digital NFC cards. </strong></p>
                    {/* <Button title='Enquiry'>Enquiry</Button> */}
                    {/* <Link to='/details/enquiry-form'>
                        <Button onClick={scrollEnquiry}>Enquiry</Button>
                    </Link> */}
                </div>
            </Container>
        </div>
    )
}
