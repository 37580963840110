"use client";
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';


export default function explore() {
    const scrollEnquiry = () => {
        let formEnquiry = document.getElementById("enquiry-form-data");
        if (formEnquiry) {
            formEnquiry.scrollIntoView()
        }
    }
    return (
        <>
            <div className='explore_contain section'>
                <Container className='container-new-big'>
                    <div className='explore_sec'>
                        <h2>
                            Why Choose Our <br /> ACW Card?
                        </h2>
                        <p>
                            ACW <strong> NFC Cards </strong> give your digital identity a revamp with our dynamic platform. You can combine personal and professional portfolios on your smartphone in seconds. With this <strong> NFC Business Card</strong>, you get expand your network, minimize paper clutter, and enjoy the ease of sharing and connecting with everyone you meet. ACW Card is not just a digital card, it is a cost-effective, scalable, and timeless solution for empowering your brand.
                        </p>
                        <div className='website_btn'>
                            {/* <Link to="#" title='Enquiry'>Enquiry</Link> */}
                            {/* <Link to='/details/enquiry-form'>
                                <Button onClick={scrollEnquiry}>Enquiry</Button>
                            </Link> */}
                        </div>
                    </div>
                </Container>
            </div>
            <div className='digitalia_card'>
                <Container className='container-new-big'>
                    <div className='digitalia'>
                        <h2>
                            Benefits Of ACW <br />
                            Digitalia Cards
                        </h2>

                        <div className='card_sec_digitalia'>
                            <Row>
                                <Col lg={4}>
                                    <div className='digi_card_img'>
                                        <img src='../images/card/digitaliacard.png' alt='digitaliacard' />
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <p className='digi_card_sub'>Spread your network with the power of ACW Cards.</p>
                                    <ul className='digitalia'>
                                        <li><span><IoIosArrowForward /></span> <span> Make multiple connections with a single ACW Card.</span></li>
                                        <li><span><IoIosArrowForward /></span> <span> Go live in seconds with PVC and <straong>Metal NFC Cards</straong>.</span></li>
                                    </ul>
                                </Col>
                                <Col lg={4} className='last_card_div'>
                                    <ul className='digitalia'>
                                        <li><span><IoIosArrowForward /></span><span> Eliminate paper-clutter and share profiles through app and browser.</span></li>
                                        <li><span><IoIosArrowForward /></span> <span>Enjoy timeless card that can be updated infinitely.</span></li>
                                    </ul>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </>

    )
}
