import React from 'react'
import { Row, Tab, Tabs, Col, Container, Button, Card } from 'react-bootstrap';

export default function about({ featureDivRef }) {
    return (
        <>
            <div className='about_page_sec'>
                <div className='order_sec section'>
                <div className='order_section'>
                    <div className='react_videoplayer_innerpage'>
                        {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                        <video autoPlay  loop volume muted>
                            {/* Provide the video source */}
                            <source src="../videos/about.mov" type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>

                </div>
            </div>
                <div className='about_part'>
                    <Container className='container-new-big'>
                        <div className='about_hede_content'>
                            <h3>About ACW Card: Redefining Digital Identity </h3>
                            <p>Our innovative <strong> digital business cards </strong> empower you to create, customize, and share a dynamic digital portfolio. This is the <strong> best digital business card </strong> that balances the between your personal and professional life. </p>
                        </div>
                        <div className='about_card_img' id='features_new' ref={featureDivRef}>
                            <Row className='justify-content-md-center'>
                                <Col lg={5}>
                                    <div className='aboutcard_card_img'>
                                        <img src='../images/about/aboutcard1.png' alt='aboutcard1' />
                                    </div>
                                </Col>
                                <Col lg={5}>
                                    <div className='aboutcard_card_img'>
                                        <img src='../images/about/aboutcard2.png' alt='aboutcard2' />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='about_card_content'>
                            <Row className='justify-content-md-center'>
                                <Col lg={4} className='mt-3'>
                                    <div className='about_card_box'>
                                        <p>Establish multiple connections with a single ACW card.</p>
                                    </div>
                                </Col>
                                <Col lg={4} className='mt-3'>
                                    <div className='about_card_box'>
                                        <p>100% compatible with both iOS & Android.</p>
                                    </div>
                                </Col>
                                <Col lg={4} className='mt-3'>
                                    <div className='about_card_box'>
                                        <p>A timeless card that can be updated infinitely.</p>
                                    </div>
                                </Col>
                            </Row>


                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}
