"use client";
import React from 'react';
import { Container } from 'react-bootstrap';
import ReactPlayer from 'react-player';
export default function order() {
    return (
        <div className='order_sec section'>
            <div className='order_section'>
                <div className='react_videoplayer_landing'>
                    {/* <ReactPlayer url='../videos/testvideo.mp4' volume autoPlay playing loop controls /> */}
                    <video autoPlay  loop volume muted>
                        {/* Provide the video source */}
                        <source src="../videos/landing.mov" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>

            </div>
        </div>
    )
}
