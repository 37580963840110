import React, { useState } from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Emailvalidate } from "../../common/validate";
import ImportedURL from "../../common/api";
import axios from "axios";
import { Error, Success } from "../../common/swal";

export default function Logins() {

    const [data, setData] = useState({});
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [emailValidError, setEmailValidError] = useState(false);
    const [spinner, setSpinner] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        const getData = {
            ...data,
            [name]: value
        }
        setData(getData);
        if (name == 'email') {
            if (value) {
                setEmailValidError(!Emailvalidate(value))
            } else {
                setEmailValidError(false)
            }
            setEmailError(false);
        }
        if (name == 'password') {
            setPasswordError(false);
        }
    };

    const submit = (e) => {
        const newData = { ...data };
        for (let key in newData) {
            if (newData.hasOwnProperty(key) && typeof newData[key] === 'string') {
                newData[key] = newData[key].trim();
            }
        }
        let valid = 1;
        if (!newData.email) {
            valid = 0;
            setEmailError(true);
        }
        if (!newData.password) {
            valid = 0;
            setPasswordError(true);
        }
        if (emailValidError) {
            valid = 0;
        }
        if (valid) {
            localStorage.removeItem('acwtoken');
            localStorage.removeItem('type');
            setSpinner(true);
            axios.get('https://ipapi.co/json/').then((response) => {
                let data = response.data;
                const formData = {
                    email: (newData.email).toLowerCase(),
                    password: newData.password,
                    time: new Date(),
                    ip: data.ip,
                    city: data.city,
                    region: data.region,
                    country: data.country_name,
                    countrycode: data.country_code,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    postal: data.postal,
                    currency: data.currency
                }
                axios.post(ImportedURL.API.login, formData)
                    .then((res) => {
                        setSpinner(false);
                        Success('Logged in successfully');
                        const { token, type } = res.data;
                        window.location.href = ImportedURL.SITEURL + "?uniqueId=" + token + "&type=" + type
                            ;
                    }).catch(({ response }) => {
                        setSpinner(false);
                        if (response) {
                            if (response.status == 401) {
                                Error('Invalid email')
                            } else if (response.status == 402) {
                                Error('Invalid password')
                            } else if (response.status == 510) {
                                Error('Email does not exit')
                            } else if (response.status == 502) {
                                Error(response.status + ' Bad Gateway')
                            } else if (response.status == 500) {
                                Error('Internal Server Error')
                            } else if (response.status == 400) {
                                Error('Bad request')
                            } else if (response.status == 409) {
                                Error('Block your account please contact admin')
                            }
                        }
                    });
            }).catch((error) => {
                console.log(error);
            });
        }
    }
    return (
        <>
            <div className="login">
                <Container className="container-new-big">
                    <div className="login_div">
                        <Row>
                            <Col lg={6}>
                                <div className="left-login">
                                    <img src="../images/login.png" alt="login-img" />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="right-login">
                                    <h4 className="login_txt">Login To Your Account</h4>
                                    <div className="mb-3">
                                        <input type="text" value={data.email} onChange={onChange} name='email' className="form-control" id="exampleFormControlInput1" placeholder="Your email address" />
                                        {emailError ? <span className='error-message'>Email is required</span> : ''}
                                        {emailValidError ? <span className='error-message'>Enter valid email</span> : ''}
                                    </div>
                                    <div className="mb-3">
                                        <input type="password" value={data.password} onChange={onChange} name='password' className="form-control" id="exampleFormControlInput1" placeholder="Enter Password" />
                                        {passwordError ? <span className='error-message'>Password is required</span> : ''}
                                    </div>
                                    <div className="login_btn">
                                        {
                                            spinner
                                                ?
                                                <Button className="login_txt">Login</Button>
                                                :
                                                <Button className="login_txt" onClick={submit}>Login</Button>
                                        }
                                    </div>
                                    {/* <div className="forgot_password">
                                        <p className="forgot_txt">Forgot Password?</p>
                                        <p className="forgot_txt">Don't have login details?<a className="click_txt" href="#">Click Here</a> </p>
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}