// "use client";
import React, { useEffect, useRef } from "react";
import Pricings from "./pricing";
import Bulk from "../home/bulk";
import Blackmetal from "./black-metal";
import Related_products from "./related-products";
import { useParams } from 'react-router-dom';
export default function Pricing() {
    const { id } = useParams()
    const targetDivRef = useRef();
    useEffect(() => {
        console.log("targetDivRef", targetDivRef.current);
        if (targetDivRef.current && id) {
            targetDivRef.current.scrollIntoView()
        }
    }, []);
    return (
        <>
            <Pricings />
            <Blackmetal targetDivRef={targetDivRef} />
            <Related_products />
            {/* <Bulk /> */}
        </>
    );
}